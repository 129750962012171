import DrynessTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-dryness/dryness/DrynessTemplate';

import {
  AMOUNT,
  BOOLEAN,
  DRYNESS_OCCURRENCE,
  FACIAL_DRYNESS,
  GENDER
} from '@/modules/questionnaire/api/constants';

export default {
  title: 'PhotoAnalysisSteps/SkinDryness/Dryness/DrynessTemplate'
};

const createStory = componentProps => () => ({
  components: { DrynessTemplate },
  componentProps,
  wrapStyles: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  template: `<div :style='$options.wrapStyles'>
    <dryness-template v-bind='$options.componentProps' />
  </div>`
});

export const FeelNoDryness = createStory({
  gender: GENDER.MALE,
  skinDryness: AMOUNT.NO,
  skinDrynessAiSelected: AMOUNT.NO
});

export const HasSomeDryness = createStory({
  gender: GENDER.MALE,
  skinDryness: AMOUNT.MODERATELY,
  skinDrynessAiSelected: AMOUNT.MODERATELY,
  facialDrynessLocations: FACIAL_DRYNESS.CHEEKS_AND_TZONE,
  facialDrynessLocationsAiSelected: FACIAL_DRYNESS.CHEEKS_AND_TZONE,
  hasSomeDryness: true,
  drynessOccurrence: [DRYNESS_OCCURRENCE.ALL_DAY],
  city: 'Moscow'
});

export const ClimateDryness = createStory({
  gender: GENDER.MALE,
  skinDryness: AMOUNT.MODERATELY,
  facialDrynessLocations: FACIAL_DRYNESS.CHEEKS_AND_TZONE,
  hasSomeDryness: true,
  drynessCausedByClimate: BOOLEAN.YES,
  drynessCausedByClimateAiSelected: BOOLEAN.YES,
  drynessCausedByClimateVisible: true,
  drynessOccurrence: [DRYNESS_OCCURRENCE.ALL_DAY],
  drynessOccurrenceAiSelected: [DRYNESS_OCCURRENCE.ALL_DAY],
  city: 'Moscow'
});
